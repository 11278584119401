.location {
	width: 100%;
	display: inline-block;
	margin: 0 auto;  

	@media (min-width: 62em) {
	    float: left;
	    width: 50%;
	}

	&:after {
		content: '';
		display: inline-block;
		clear: both;
	}

}