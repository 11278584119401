//Gallery SCSS
.gallery {
	display: grid;
	grid-gap: 10px;
   	grid-template-columns: repeat(auto-fill, minmax(250px,1fr));
   	grid-auto-rows: 5px;

   	.gallery-item-image {
   		width: 100%;
   	}
}