@mixin guide {
	.guide {
		border: 1px solid $dark-colour;
		display: inline-block;
		padding: 16px;
		margin: 16px 0;
		width: 200px;

		p:last-child {
			padding-bottom: 0;
		}
	}
}

@include guide;