@import "responsive-nav";
@import "../base/colours";

.navbar {
  @media(min-width: 40em) {
    overflow:hidden;
    width:auto;
  }
}

.nav-collapse {
  border-bottom: 5px solid lighten($dark-colour, 50%);
  border-top: 5px solid $dark-colour;
  width: 100%;

  @media (min-width: 40em) {
    border: none;
  }

  .nav-item {
    @media (min-width: 40em) {
      display: inline;
      width: auto;
    }
  }
}

.nav-item {
  border-left: 5px solid transparent;
  float:left;

  &:hover {
    border-left: 5px solid $dark-colour;
  }

  @media (min-width: 40em) {
    border-left:none;

    &:hover {
      border-left:none;
    }
  }
}

.nav-toggle,
.nav-link {
  display: inline-block;
  font-size: 1.5em;
  padding: 10px;
  text-decoration: none;

  @media (min-width: 40em) {
    font-size: 0.8em;
  }
}

.nav-toggle {
  @media (min-width: 40em) {
    display: none;
  }
}