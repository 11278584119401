@mixin tables($size) {
	@media (min-width: $size) {
		.table {
			display: table;
		}

		.td {
			display: table-cell;
			vertical-align: top;
		}
	}
}

@include tables(50em);