//Contact box SCSS

@mixin contact {
	.contact {
		border: 1px solid $dark-colour;
		display: inline-block;
		padding: 16px;

		p:last-child {
			padding-bottom: 0;
		}
	}
}

@include contact;