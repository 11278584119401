//Comments SCSS
.comment-text {
	padding-bottom: 0;
}

.comment-name {
	font-weight: 700;
}

@media (min-width: 40em) {
  .comment-image {
    margin: 0 0 0 16px;
    width: 240px;
    height: auto;
    float: right;
  }
}