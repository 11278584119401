body {
	font-size: 16px;
	overflow-x: hidden;
}

nav,
.header,
.content {
	padding: 5px;
	margin: 0 auto;
	max-width: 940px;
	position: relative;

	&.narrow {
		max-width: 880px;
	}
}