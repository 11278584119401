//Colour scheme

//Black & white
$dark-colour: #333;
$light-colour: #FFF;

//Main colours
$primary-colour: #FF5E99;
$complement-colour: adjust-hue($primary-colour, 180); //#5effc4
$secondary-colour: adjust-hue($primary-colour, 120); //#99ff5e
$tertiary-colour: adjust-hue($primary-colour, -120); //#5e99ff

//Additional colours
$link-colour: #c40048;
$link-hover-colour: #2b0010;