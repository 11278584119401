//Map viewer SCSS

@mixin map-viewer {
	#mapviewer {
		max-width: 100%;
		width: 320px;
		overflow: hidden;

		@media (min-width: 321px) {
			max-width: 100%;
			width: 500px;
		}

		@media (min-width: 40em) {
			display: inline-block;
			margin: 0 auto;
		}
	}

	.map-frame {
		overflow: hidden;
	}
}

@include map-viewer