//Centered block SASS

@mixin centered-block {
	@media (min-width: 40em) {
		.centered-block {
			display: inline-block;
		}
	}
}

@include centered-block;