//Tables SCSS
table {
	float:left;
	margin-bottom: 16px;
	width: 100%;

	@media (min-width: 40em) {
		width: 50%;
	}

  &.no-float {
    float: none;
  }
}

td {
	@include font-main;
}

.heading {
	font-weight: 700;
}