// Tanya Couture Boutique SCSS

//MS Viewport fix
@import "util/ms-fix";

//Import utilities
@import "util/bfc";
@import "util/clearfix";
@import "util/sr-only";
@import "util/img-responsive";

//Reset & import base layout
@import "base/reset";
@import "base/layout";
@import "base/colours";
@import "base/table-layout";
@import "base/text";

//Import header/navigation
@import "header/logo";
@import "header/navbar";

//Import body elements
@import "body/centered-block";
@import "body/contact";
@import "body/comments";
@import "body/floats";
@import "body/mapviewer";
@import "body/gallery";
@import "body/carousel";
@import "body/guide";
@import "body/body-img";
@import "body/lh-img";
@import "body/tables";
@import "body/location";
@import "body/rules";
@import "body/fb-logo";
