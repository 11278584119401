@mixin base-rule {
	width: 100%;
	margin-bottom: 24px;
}

.full-rule {
	@include base-rule;
}


.location-rule {
	@include base-rule;

	float:left;
}