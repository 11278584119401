@mixin floats {
	.left {
		float: left;
	}

	.right {
		float: right;
	}
}

@include floats;