@mixin font-main {
	font-family: 'Century Gothic', CenturyGothic, AppleGothic, sans-serif;
}

h1, h2, h3, h4, h5, h6, p, a, span {
	@include font-main;
	color: $dark-colour;
}

h2, h3, h4, h5, h6, p {
	padding-bottom: 16px;
}

h1 {
	font-size: 2em;
}

h2 {
	font-size: 1.5em;
	padding-bottom: 32px;

	@media (min-width: 40em) {
		text-align: center;
	}
}

h3 {
	font-weight: 700;
	.padded & {
		padding: 0 16px 16px 0;
	}
}

a {
	color: $link-colour;

	.active &:hover {
		color: $link-colour;
		cursor: default;
	}
	&:hover {
		color: $link-hover-colour;
	}
}

.text-center {
	text-align: center;
}

.text-left {
	text-align: left;
}

.text-right {
	text-align: right;
}