//Block formatting context scss

@mixin bfc {
	.bfc-left {
		width: auto;
		overflow: hidden;
	}

	.bfc-right {
		float:right;
		width: 250px;
	}
}

//@include bfc;