//Left hand image styles
@mixin lh-img {
	.lh-img {
		float: left;
		padding: 0 16px 16px 0;
		width: 150px;
	}
}

@mixin lh-img-wide {
  .lh-img-wide {
    width: 300px;
  }
}

@include lh-img;
@include lh-img-wide;